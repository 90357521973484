import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProdutoSliderItemComponent } from 'src/app/components/produto/produto-slider-item/produto-slider-item.component';
import { ProdutoItemComponent } from 'src/app/components/produto/produto-item/produto-item.component';
import { ProdutoItemTwoComponent } from 'src/app/components/produto/produto-item-two/produto-item-two.component';
import { PipeModule } from '../../pipe/pipe.module';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import { ProdutoItemValorModule } from '../produto-item-valor/produto-item-valor.module';
import { ProdutoAvaliacaoStarComponent } from 'src/app/components/produto/produto-avaliacao-star/produto-avaliacao-star.component';
import { ProdutoAvaliacaoComponent } from 'src/app/components/produto/produto-avaliacao/produto-avaliacao.component';

@NgModule({
  declarations: [
    ProdutoSliderItemComponent,
    ProdutoItemComponent,
    ProdutoItemTwoComponent,
    ProdutoAvaliacaoStarComponent,
    ProdutoAvaliacaoComponent
  ],
  exports: [ 
    ProdutoSliderItemComponent,
    ProdutoItemTwoComponent,
    ProdutoItemComponent,
    ProdutoAvaliacaoStarComponent,
    ProdutoAvaliacaoComponent
  ],
  imports: [
    ProdutoItemValorModule,
    LazyLoadImageModule,
    PipeModule,   
    CommonModule
  ]
})
export class ProdutoPartialModule { }
