import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; //@3.4.2
import { MatDialogModule } from '@angular/material/dialog';
import { MatModalInfoDialogComponent } from './components/modais/mat-modal-info-dialog/mat-modal-info-dialog.component';
import { ModulosResolver } from './resolvers/modulos.resolver';
import { ModuloService } from './services/modulo.service'; 
import { DataService } from './services/data.service';
import { GtagService } from './services/gtag.service';
import { HeaderComponent } from './components/partial/header/header.component';
import { FooterComponent } from './components/partial/footer/footer.component';
import { BtnListaDesejoComponent } from './components/btns/btn-lista-desejo/btn-lista-desejo.component';
import { BtnCartComponent } from './components/btns/btn-cart/btn-cart.component';
import { MenuComponent } from './components/partial/menu/menu.component';
import { ServicoService } from './services/servico.service';
import { StorageService } from './services/storage.service';
import { ValidatorService } from './services/validator.service';
import { DateService } from './services/date.service';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { UsuarioLoginModal } from './components/usuario/usuario-login-modal/usuario-login-modal.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LinksUteisComponent } from './components/partial/links-uteis/links-uteis.component';
import { MenuAjudaComponent } from './components/partial/menu-ajuda/menu-ajuda.component';
import { LoaderModule } from './modules/loader/loader/loader.module';
import { ProdutoPartialModule } from './modules/produto/produto-partial/produto-partial.module';
import { ModuloModule } from './modules/modulo/modulo.module';
import "@angular/compiler";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BtnUsuarioLoginModule } from './modules/btn/btn-usuario-login/btn-usuario-login.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ProductDescriptionComponent } from './components/product/product-description/product-description.component';
import { BoxFormPesquisaComponent } from './components/box-form-pesquisa/box-form-pesquisa.component';

registerLocaleData(localePt,'pt-BR');     

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        MenuAjudaComponent,
        MenuComponent,
        BtnCartComponent,
        BtnListaDesejoComponent,
        LinksUteisComponent,
        MatModalInfoDialogComponent,
        ProductDescriptionComponent,
        BoxFormPesquisaComponent
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        MenuComponent,
        MenuAjudaComponent
    ],
    imports: [
        BtnUsuarioLoginModule,
        ModuloModule,
        LoaderModule,
        ProdutoPartialModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        RecaptchaV3Module,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        DataService,
        ModuloService,
        ModulosResolver,
        GtagService,
        ServicoService,
        StorageService,
        ValidatorService,
        DateService,
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LcDQocaAAAAANsw-mEK22KwZrwA2PaQG_J98EES' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
